import React from "react"
import Layout from "../components/layout"
import Row from "../components/row"

import bg from "../images/backgrounds/bg-2.jpg"
import gradientTop from "../images/gradients/top-gradient-1.png"
import gradientBottom from "../images/gradients/bottom-gradient-1.png"

import text from "../data/nl"
import { graphql } from "gatsby"
import CaseTagLinks from "../components/caseTagLinks"
import CustomerCase from "../components/customerCase"
import SEO from "../components/seo"
import CaseCustomerLinks from "../components/caseCustomerLinks"

// ook klanten tonen zonder case.
const customerPage = data => (
  <Layout bigfoot={false}>
    <SEO title={text.customers.meta.title} description={text.customers.meta.description}/>
    <Row image={bg} size="small" classes="pt-200" gradientTop={gradientTop}  gradientBottom={gradientBottom} id="KlantenFilters">
      <div className="column col-12">
        <h1 className="uppercase t-50 bold mb-40">{text.customers.title}</h1>
        <div className="full-width mobile-overflow-scroll">
          <CaseCustomerLinks customers={data.data.allCaseCustomers} currentPage={data.pathContext.tag} />
        </div>
      </div>
      <div className="column col-12 pt-150" id="cases">
        <CaseTagLinks tags={data.data.allCaseTags}/>
      </div>
    </Row>
    <div id="KlantenCases">
      {data.data.allCases.edges.map((customerCase, i) => (
        <CustomerCase key={customerCase.node.customer + i} customerCase={customerCase.node} i={i}/>
      ))}
    </div>
  </Layout>
)

export default customerPage

export const query = graphql`
  query customerQuery {
    allCaseTags {
      edges {
        node {
          caseTag
        }
      }
    }    
    allCases (sort: { fields: [order], order: ASC } ) {
      edges {
        node {
          customer
          background
          tags
          steps {
            title
            text
          }
        }
      }
    }
    allCaseCustomers (sort: {fields: [order], order: ASC}) {
      edges {
        node {
          name
          hasCases
          order
          image
        }
      }
    }
  }
`
